import { render, staticRenderFns } from "./homeEducation.vue?vue&type=template&id=0d83701c&scoped=true&"
import script from "./homeEducation.vue?vue&type=script&lang=js&"
export * from "./homeEducation.vue?vue&type=script&lang=js&"
import style0 from "./homeEducation.vue?vue&type=style&index=0&id=0d83701c&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0d83701c",
  null
  
)

export default component.exports